import React, { useEffect, useRef, useState } from "react";
import { ToastContainer, toast } from "react-toastify";

import {
  deleteEnglishOurPartner,
  deleteNepaliOurPartner,
  getAllOurPartner,
  saveEnglishOurPartner,
  saveEnglishOurPartnerHeading,
  saveNepaliOurPartner,
  saveNepaliOurPartnerHeading,
} from "../../../../services/api";
import { toastError, toastSuccess } from "../../../../services/ToastService";
import {
  englishConfig,
  nepaliConfig,
} from "../../../../services/joditConfigService";
import JoditEditor from "jodit-react";

const OurPartner = () => {
  const [isCollapsed, setIsCollapsed] = useState([]);
  const englishEditor = useRef(null);
  const nepaliEditor = useRef(null);
  const [formsFieldsEnglish, setformsFieldsEnglish] = useState({
    heading: "",
    partner: [
      {
        _id: "",
        image: "",
        content: "",
      },
    ],
  });

  const [formsFieldsNepali, setformsFieldsNepali] = useState({
    heading: "",
    partner: [
      {
        _id: "",
        image: "",
        content: "",
      },
    ],
  });

  const getallOurPartnerData = async () => {
    try {
      let res = await getAllOurPartner();
      if (res.data) {
        res.data.forEach((item) => {
          if (item.locale === "eng") {
            setformsFieldsEnglish((prevState) => ({
              ...prevState,
              heading: item.heading,
              partner: item.partner,
            }));
          } else if (item.locale === "nep") {
            setformsFieldsNepali((prevState) => ({
              ...prevState,
              heading: item.heading,
              partner: item.partner,
            }));
          }
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleEnglishInputChange = (index, event) => {
    const { name, value } = event.target;
    setformsFieldsEnglish((prevState) => {
      const updatedOurPartner = [...prevState.partner];
      updatedOurPartner[index][name] = value;
      return { ...prevState, partner: updatedOurPartner };
    });
  };

  const handleNepaliInputChange = (index, event) => {
    const { name, value } = event.target;
    setformsFieldsNepali((prevState) => {
      const updatedOurPartner = [...prevState.partner];
      updatedOurPartner[index][name] = value;
      return { ...prevState, partner: updatedOurPartner };
    });
  };

  const handleAddThematicAreas = () => {
    setformsFieldsEnglish({
      ...formsFieldsEnglish,
      partner: [
        ...formsFieldsEnglish.partner,
        {
          _id: "",
          image: "",
          content: "",
        },
      ],
    });

    setformsFieldsNepali({
      ...formsFieldsNepali,
      partner: [
        ...formsFieldsNepali.partner,
        {
          _id: "",
          image: "",
          content: "",
        },
      ],
    });
  };

  const handleRemoveThematicAreas = async (
    index,
    nepaliOurPartner,
    englishOurPartner
  ) => {
    if (
      nepaliOurPartner._id &&
      nepaliOurPartner._id !== "" &&
      englishOurPartner._id &&
      englishOurPartner._id !== ""
    ) {
      await deleteNepaliOurPartner(nepaliOurPartner._id);
      await deleteEnglishOurPartner(englishOurPartner._id);

      removeThematicAreas(index);
    } else {
      removeThematicAreas(index);
    }
  };

  const removeThematicAreas = (index) => {
    const updatedEnglishOurPartner = [...formsFieldsEnglish.partner];
    updatedEnglishOurPartner.splice(index, 1);
    setformsFieldsEnglish({
      ...formsFieldsEnglish,
      partner: updatedEnglishOurPartner,
    });

    const updatedNepaliOurPartner = [...formsFieldsNepali.partner];
    updatedNepaliOurPartner.splice(index, 1);
    setformsFieldsNepali({
      ...formsFieldsNepali,
      partner: updatedNepaliOurPartner,
    });
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    try {
      if (formsFieldsEnglish) {
        let data = {
          heading: formsFieldsEnglish.heading,
        };
        await saveEnglishOurPartnerHeading(data);
      }

      if (formsFieldsNepali) {
        let data = {
          heading: formsFieldsNepali.heading,
        };
        await saveNepaliOurPartnerHeading(data);
      }

      toastSuccess();
    } catch (e) {
      toastError();
      console.log(e);
    }
  };

  const handleImageChange = (index, event) => {
    const file = event.target.files[0];

    setformsFieldsEnglish((prevState) => {
      const updatedOurPartner = [...prevState.partner];
      updatedOurPartner[index].image = file;
      return { ...prevState, partner: updatedOurPartner };
    });

    setformsFieldsNepali((prevState) => {
      const updatedOurPartner = [...prevState.partner];
      updatedOurPartner[index].image = file;
      return { ...prevState, partner: updatedOurPartner };
    });
  };

  useEffect(() => {
    setIsCollapsed(new Array(formsFieldsEnglish.partner.length).fill(true));
  }, [formsFieldsEnglish.partner.length]);

  useEffect(() => {
    getallOurPartnerData();
  }, []);

  const toggleCollapse = (index) => {
    setIsCollapsed((prevState) =>
      prevState.map((state, i) => (i === index ? !state : state))
    );
  };

  const handleSaveThematicAreas = async (index, e) => {
    e.preventDefault();
    try {
      if (formsFieldsEnglish) {
        const formData = new FormData();

        formData.append("image", formsFieldsEnglish.partner[index].image);
        formData.append("content", formsFieldsEnglish.partner[index].content);
        formData.append("id", formsFieldsEnglish.partner[index]._id);
        await saveEnglishOurPartner(formData);
      }

      if (formsFieldsNepali) {
        const formData = new FormData();
        formData.append("image", formsFieldsNepali.partner[index].image);
        formData.append("content", formsFieldsNepali.partner[index].content);
        formData.append("id", formsFieldsNepali.partner[index]._id);
        await saveNepaliOurPartner(formData);
      }
      toastSuccess();
    } catch (error) {
      console.log(error);
      toastError();
    }
  };
  return (
    <>
      <main className="main">
        <div className="pagetitle">
          <h1>Our Partner</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="index.html">Auth</a>
              </li>
              <li className="breadcrumb-item">Components</li>
              <li className="breadcrumb-item active">Our Partner</li>
            </ol>
          </nav>
        </div>
        <section className="section">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="mt-3 mb-3 d-flex justify-content-end">
                    <button
                      type="submit"
                      onClick={onSubmit}
                      className="btn btn-primary"
                    >
                      Save
                    </button>
                  </div>
                  <div className="row ">
                    <div className="col-md-6">
                      <h4 className="card-title">English </h4>
                      <hr className="border-2" />
                      <div className="mb-3">
                        <label className="form-label">Heading</label>
                        <textarea
                          type="text"
                          className="form-control"
                          value={formsFieldsEnglish.heading}
                          onChange={(e) =>
                            setformsFieldsEnglish({
                              ...formsFieldsEnglish,
                              heading: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <h4 className="card-title">Nepali </h4>
                      <hr className="border-2" />
                      <div className="mb-3">
                        <label className="form-label">Heading</label>
                        <textarea
                          type="text"
                          className="form-control"
                          value={formsFieldsNepali.heading}
                          onChange={(e) =>
                            setformsFieldsNepali({
                              ...formsFieldsNepali,
                              heading: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                  </div>

                  {formsFieldsEnglish.partner.map((partner, index) => (
                    <>
                      <div key={index} className="parameters-container">
                        <div
                          className="parameters-header"
                          onClick={() => toggleCollapse(index)}
                        >
                          <span className="parameters-title">
                            {index + 1}. Partners
                          </span>
                          <div className="icon-containers">
                            <button className="parameters-toggle-btn">
                              <i
                                onClick={handleAddThematicAreas}
                                class="bi bi-plus-lg"
                              ></i>
                            </button>
                            <button className="parameters-toggle-btn">
                              <i
                                onClick={() =>
                                  handleRemoveThematicAreas(
                                    index,
                                    formsFieldsNepali.partner[index],
                                    partner
                                  )
                                }
                                class="bi bi-trash3"
                              ></i>
                            </button>
                            <button
                              className="parameters-toggle-btn"
                              id={`toggleButton${index}`}
                            >
                              {isCollapsed[index] ? "\u25BC" : "\u25B2"}
                            </button>
                          </div>
                        </div>
                        <div
                          className="parameters-body"
                          id={`parametersBody${index}`}
                          style={{
                            display: isCollapsed[index] ? "none" : "block",
                          }}
                        >
                          <div className=" border border-dark rounded">
                            <div className="d-flex mt-3 container">
                              <h5 className="card-title">
                                {index + 1}. Our Partner
                              </h5>
                              <hr className="border-2" />
                              <div className=" justify-content-end">
                                <button
                                  onClick={(e) =>
                                    handleSaveThematicAreas(index, e)
                                  }
                                  className="btn btn-primary"
                                >
                                  Save
                                </button>
                              </div>
                            </div>
                            <div className=" row m-2 ">
                              <div className="col-md-6 mb-3">
                                <label className="form-label">
                                  Image (Best Fit Size: height: 200px; width: 300px;)
                                </label>
                                <input
                                  type="file"
                                  onChange={(e) => handleImageChange(index, e)}
                                />
                              </div>

                              <div className=" mb-3 col-md-6">
                                <img
                                  style={{
                                    height: "200px",
                                    width: "300px",
                                    objectFit: "contain",
                                  }}
                                  src={partner.image}
                                  alt="Uploaded Photograph"
                                />
                              </div>

                              <div className="col-md-6">
                                <h4 className="card-title">English </h4>
                                <hr className="border-2" />

                                <div className=" mb-3">
                                  <label className="form-label">Content</label>
                                  <JoditEditor
                                    ref={englishEditor}
                                    value={partner.content}
                                    tabIndex={1}
                                    config={englishConfig}
                                    onChange={(content) =>
                                      handleEnglishInputChange(index, {
                                        target: {
                                          name: "content",
                                          value: content,
                                        },
                                      })
                                    }
                                  />
                                </div>
                              </div>

                              <div className="col-md-6">
                                <h4 className="card-title">Nepali </h4>
                                <hr className="border-2" />

                                <div className="mb-3">
                                  <label className="form-label">content</label>
                                  <JoditEditor
                                    ref={nepaliEditor}
                                    tabIndex={1}
                                    value={
                                      formsFieldsNepali.partner[index].content
                                    }
                                    config={nepaliConfig}
                                    onChange={(content) =>
                                      handleNepaliInputChange(index, {
                                        target: {
                                          name: "content",
                                          value: content,
                                        },
                                      })
                                    }
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="m-3 d-flex justify-content-end  ">
                              <button
                                onClick={() =>
                                  handleRemoveThematicAreas(
                                    index,
                                    formsFieldsNepali.partner[index],
                                    partner
                                  )
                                }
                                className="btn btn-primary"
                              >
                                Remove Our Partner
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>

                      <br />
                    </>
                  ))}

                  <div className="mt-3 mb-3 d-flex justify-content-end">
                    <button
                      className="btn btn-primary"
                      onClick={handleAddThematicAreas}
                    >
                      Add Our Partner
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <ToastContainer />
      </main>
    </>
  );
};

export default OurPartner;
