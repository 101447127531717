import JoditEditor from "jodit-react";
import React, { useEffect, useRef, useState } from "react";
import { englishConfig } from "../../../../services/joditConfigService";

import { toastError, toastSuccess } from "../../../../services/ToastService";
import { ToastContainer } from "react-toastify";
import {
  deleteEnglishVacancy,
  deleteNepaliVacancy,
  getAllVacancy,
  saveEnglishVacancy,
  saveNepaliVacancy,
} from "../../../../services/api";

const GetInvolvedVacancy = () => {
  const editorRef = useRef(null);
  const [isCollapsed, setIsCollapsed] = useState([]);
  const [formsFieldsEnglish, setformsFieldsEnglish] = useState({
    vacancy: [
      {
        _id: "",
        name: "",
        content: "",
      },
    ],
  });

  const [formsFieldsNepali, setformsFieldsNepali] = useState({
    vacancy: [
      {
        _id: "",
        name: "",
        content: "",
      },
    ],
  });

  const getAllvacancy = async () => {
    try {
      let res = await getAllVacancy();
      if (res.data) {
        res.data.forEach((item) => {
          if (item.locale === "eng") {
            setformsFieldsEnglish((prevState) => ({
              ...prevState,
              vacancy: item.vacancy,
            }));
          } else if (item.locale === "nep") {
            setformsFieldsNepali((prevState) => ({
              ...prevState,
              vacancy: item.vacancy,
            }));
          }
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleEnglishInputChange = (index, event) => {
    const { name, value } = event.target;
    setformsFieldsEnglish((prevState) => {
      const updatedVacancy = [...prevState.vacancy];
      updatedVacancy[index][name] = value;
      return { ...prevState, vacancy: updatedVacancy };
    });
  };

  const handleNepaliInputChange = (index, event) => {
    const { name, value } = event.target;
    setformsFieldsNepali((prevState) => {
      const updatedVacancy = [...prevState.vacancy];
      updatedVacancy[index][name] = value;
      return { ...prevState, vacancy: updatedVacancy };
    });
  };

  const handleAddvacancy = () => {
    setformsFieldsEnglish({
      ...formsFieldsEnglish,
      vacancy: [
        ...formsFieldsEnglish.vacancy,
        {
          _id: "",
          name: "",
          content: "",
        },
      ],
    });

    setformsFieldsNepali({
      ...formsFieldsNepali,
      vacancy: [
        ...formsFieldsNepali.vacancy,
        {
          _id: "",
          name: "",
          content: "",
        },
      ],
    });
  };

  const handleRemovevacancy = async (index, nepalivacancy, englishvacancy) => {
    if (
      nepalivacancy._id &&
      nepalivacancy._id !== "" &&
      englishvacancy._id &&
      englishvacancy._id !== ""
    ) {
      await deleteNepaliVacancy(nepalivacancy._id);
      await deleteEnglishVacancy(englishvacancy._id);

      removeVacancy(index);
    } else {
      removeVacancy(index);
    }
  };

  const removeVacancy = (index) => {
    const updatedEnglishVacancy = [...formsFieldsEnglish.vacancy];
    updatedEnglishVacancy.splice(index, 1);
    setformsFieldsEnglish({
      ...formsFieldsEnglish,
      vacancy: updatedEnglishVacancy,
    });

    const updatedNepaliVacancy = [...formsFieldsNepali.vacancy];
    updatedNepaliVacancy.splice(index, 1);
    setformsFieldsNepali({
      ...formsFieldsNepali,
      vacancy: updatedNepaliVacancy,
    });
  };

  useEffect(() => {
    setIsCollapsed(new Array(formsFieldsEnglish.vacancy.length).fill(true));
  }, [formsFieldsEnglish.vacancy.length]);

  useEffect(() => {
    getAllvacancy();
  }, []);

  const toggleCollapse = (index) => {
    setIsCollapsed((prevState) =>
      prevState.map((state, i) => (i === index ? !state : state))
    );
  };

  const handleSaveVacancy = async (index, e) => {
    e.preventDefault();
    try {
      if (formsFieldsEnglish) {
        const formData = new FormData();
        formData.append("name", formsFieldsEnglish.vacancy[index].name);
        formData.append("content", formsFieldsEnglish.vacancy[index].content);
        formData.append("id", formsFieldsEnglish.vacancy[index]._id);

        await saveEnglishVacancy(formData);
      }

      if (formsFieldsNepali) {
        const formData = new FormData();
        formData.append("name", formsFieldsNepali.vacancy[index].name);
        formData.append("content", formsFieldsNepali.vacancy[index].content);
        formData.append("id", formsFieldsNepali.vacancy[index]._id);

        await saveNepaliVacancy(formData);
      }
      toastSuccess();
    } catch (error) {
      console.log(error);
      toastError();
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body">
              <div className="pagetitle">
                <h3>Vacancy</h3>
              </div>
              {formsFieldsEnglish.vacancy.map((vacancies, index) => (
                <React.Fragment key={index}>
                  <div className="parameters-container">
                    <div
                      className="parameters-header"
                      onClick={() => toggleCollapse(index)}
                    >
                      <span className="parameters-title">
                        {index + 1}. Vacancy
                      </span>
                      <div className="icon-containers">
                        <button className="parameters-toggle-btn">
                          <i
                            onClick={handleAddvacancy}
                            class="bi bi-plus-lg"
                          ></i>
                        </button>
                        <button className="parameters-toggle-btn">
                          <i
                            onClick={() =>
                              handleRemovevacancy(
                                index,
                                formsFieldsNepali.vacancy[index],
                                vacancies
                              )
                            }
                            class="bi bi-trash3"
                          ></i>
                        </button>
                        <button
                          className="parameters-toggle-btn"
                          id={`toggleButton${index}`}
                        >
                          {isCollapsed[index] ? "\u25BC" : "\u25B2"}
                        </button>
                      </div>
                    </div>
                    <div
                      className="parameters-body"
                      id={`parametersBody${index}`}
                      style={{
                        display: isCollapsed[index] ? "none" : "block",
                      }}
                    >
                      <div className=" border border-dark rounded">
                        <div className="d-flex mt-3 container">
                          <h5 className="card-title">{index + 1}. Vacancy</h5>
                          <hr className="border-2" />
                          <div className=" justify-content-end">
                            <button
                              onClick={(e) => handleSaveVacancy(index, e)}
                              className="btn btn-primary"
                            >
                              Save
                            </button>
                          </div>
                        </div>
                        <div className=" row m-2 ">
                          <div className="col-md-6">
                            <h4 className="card-title">English </h4>
                            <hr className="border-2" />
                            <div className=" mb-3">
                              <label className="form-label">Name</label>
                              <input
                                type="text"
                                className="form-control"
                                value={vacancies.name}
                                name="name"
                                onChange={(e) =>
                                  handleEnglishInputChange(index, e)
                                }
                              />
                            </div>

                            <div className=" mb-3">
                              <JoditEditor
                                ref={editorRef}
                                tabIndex={1}
                                value={vacancies.content}
                                config={englishConfig}
                                onChange={(content) =>
                                  handleEnglishInputChange(index, {
                                    target: {
                                      name: "content",
                                      value: content,
                                    },
                                  })
                                }
                              />
                            </div>
                          </div>

                          <div className="col-md-6">
                            <h4 className="card-title">Nepali </h4>
                            <hr className="border-2" />
                            <div className="mb-3">
                              <label className="form-label">Name</label>
                              <input
                                type="text"
                                className="form-control"
                                value={formsFieldsNepali.vacancy[index].name}
                                name="name"
                                onChange={(e) =>
                                  handleNepaliInputChange(index, {
                                    target: {
                                      name: "name",
                                      value: e.target.value,
                                    },
                                  })
                                }
                              />
                            </div>

                            <div className=" mb-3">
                              <JoditEditor
                                ref={editorRef}
                                tabIndex={1}
                                value={formsFieldsNepali.vacancy[index].content}
                                config={englishConfig}
                                onChange={(content) =>
                                  handleNepaliInputChange(index, {
                                    target: {
                                      name: "content",
                                      value: content,
                                    },
                                  })
                                }
                              />
                            </div>
                          </div>
                        </div>

                        <div className="m-3 d-flex justify-content-end  ">
                          <button
                            onClick={() =>
                              handleRemovevacancy(
                                index,
                                formsFieldsNepali.vacancy[index],
                                vacancies
                              )
                            }
                            className="btn btn-primary"
                          >
                            Remove Work
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <br />
                </React.Fragment>
              ))}

              <div className="mt-3 mb-3 d-flex justify-content-end">
                <button className="btn btn-primary" onClick={handleAddvacancy}>
                  Add Work
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer />
    </>
  );
};

export default GetInvolvedVacancy;
