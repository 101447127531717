import React from "react";
import { useEffect, useState } from "react";
import { toastError, toastSuccess } from "../../../../services/ToastService";
import { ToastContainer } from "react-toastify";
import {
  deleteEnglishOurApproach,
  deleteNepaliOurApproach,
  getAllOurApproach,
  saveEnglishOurApproach,
  saveEnglishOurApproachHeading,
  saveNepaliOurApproach,
  saveNepaliOurApproachHeading,
} from "../../../../services/api";

const AboutUsOurApproach = () => {
  const [isCollapsed, setIsCollapsed] = useState([]);
  const [formsFieldsEnglish, setformsFieldsEnglish] = useState({
    heading: "",
    approach: [
      {
        _id: "",
        image: "",
        title: "",
      },
    ],
  });

  const [formsFieldsNepali, setformsFieldsNepali] = useState({
    heading: "",
    approach: [
      {
        _id: "",
        image: "",
        title: "",
      },
    ],
  });

  const getallOurApproach = async () => {
    try {
      let res = await getAllOurApproach();
      if (res.data) {
        res.data.forEach((item) => {
          if (item.locale === "eng") {
            setformsFieldsEnglish((prevState) => ({
              ...prevState,
              heading: item.heading,
              approach: item.approach,
            }));
          } else if (item.locale === "nep") {
            setformsFieldsNepali((prevState) => ({
              ...prevState,
              heading: item.heading,
              approach: item.approach,
            }));
          }
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleEnglishInputChange = (index, event) => {
    const { name, value } = event.target;
    setformsFieldsEnglish((prevState) => {
      const updatedOurApproach = [...prevState.approach];
      updatedOurApproach[index][name] = value;
      return { ...prevState, approach: updatedOurApproach };
    });
  };

  const handleNepaliInputChange = (index, event) => {
    const { name, value } = event.target;
    setformsFieldsNepali((prevState) => {
      const updatedOurApproach = [...prevState.approach];
      updatedOurApproach[index][name] = value;
      return { ...prevState, approach: updatedOurApproach };
    });
  };

  const handleAddOurApproach = () => {
    setformsFieldsEnglish({
      ...formsFieldsEnglish,
      approach: [
        ...formsFieldsEnglish.approach,
        {
          _id: "",
          image: "",
          title: "",
        },
      ],
    });

    setformsFieldsNepali({
      ...formsFieldsNepali,
      approach: [
        ...formsFieldsNepali.approach,
        {
          _id: "",
          image: "",
          title: "",
        },
      ],
    });
  };

  const handleRemoveOurApproach = async (
    index,
    nepaliOurApproach,
    enslishOurApproach
  ) => {
    if (
      nepaliOurApproach._id &&
      nepaliOurApproach._id !== "" &&
      enslishOurApproach._id &&
      enslishOurApproach._id !== ""
    ) {
      await deleteNepaliOurApproach(nepaliOurApproach._id);
      await deleteEnglishOurApproach(enslishOurApproach._id);

      removeOurApproach(index);
    } else {
      removeOurApproach(index);
    }
  };

  const removeOurApproach = (index) => {
    const updatedEnglishOurApproach = [...formsFieldsEnglish.approach];
    updatedEnglishOurApproach.splice(index, 1);
    setformsFieldsEnglish({
      ...formsFieldsEnglish,
      approach: updatedEnglishOurApproach,
    });

    const updatedNepaliOurApproach = [...formsFieldsNepali.approach];
    updatedNepaliOurApproach.splice(index, 1);
    setformsFieldsNepali({
      ...formsFieldsNepali,
      approach: updatedNepaliOurApproach,
    });
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    try {
      if (formsFieldsEnglish) {
        let data = {
          heading: formsFieldsEnglish.heading,
        };
        await saveEnglishOurApproachHeading(data);
      }

      if (formsFieldsNepali) {
        let data = {
          heading: formsFieldsNepali.heading,
        };
        await saveNepaliOurApproachHeading(data);
      }

      toastSuccess();
    } catch (e) {
      toastError();
      console.log(e);
    }
  };

  const handleImageChange = (index, event) => {
    const file = event.target.files[0];

    setformsFieldsEnglish((prevState) => {
      const updatedOurApproach = [...prevState.approach];
      updatedOurApproach[index].image = file;
      return { ...prevState, approach: updatedOurApproach };
    });

    setformsFieldsNepali((prevState) => {
      const updatedOurApproach = [...prevState.approach];
      updatedOurApproach[index].image = file;
      return { ...prevState, approach: updatedOurApproach };
    });
  };

  useEffect(() => {
    setIsCollapsed(new Array(formsFieldsEnglish.approach.length).fill(true));
  }, [formsFieldsEnglish.approach.length]);

  useEffect(() => {
    getallOurApproach();
  }, []);

  const toggleCollapse = (index) => {
    setIsCollapsed((prevState) =>
      prevState.map((state, i) => (i === index ? !state : state))
    );
  };

  const handleSaveOurApproach = async (index, e) => {
    e.preventDefault();
    try {
      if (formsFieldsEnglish) {
        const formData = new FormData();
        formData.append("title", formsFieldsEnglish.approach[index].title);
        formData.append("image", formsFieldsEnglish.approach[index].image);
        formData.append("id", formsFieldsEnglish.approach[index]._id);
        await saveEnglishOurApproach(formData);
      }

      if (formsFieldsNepali) {
        const formData = new FormData();
        formData.append("title", formsFieldsNepali.approach[index].title);
        formData.append("image", formsFieldsNepali.approach[index].image);
        formData.append("id", formsFieldsNepali.approach[index]._id);
        await saveNepaliOurApproach(formData);
      }
      toastSuccess();
    } catch (error) {
      console.log(error);
      toastError();
    }
  };

  return (
    <>
      <main className="main">
        <div className="pagetitle">
          <h1>Our Approach</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="index.html">Auth</a>
              </li>
              <li className="breadcrumb-item">Components</li>
              <li className="breadcrumb-item active">Our Approach</li>
            </ol>
          </nav>
        </div>
        <section className="section">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="mt-3 mb-3 d-flex justify-content-end">
                    <button
                      type="submit"
                      onClick={onSubmit}
                      className="btn btn-primary"
                    >
                      Save
                    </button>
                  </div>
                  <div className="row ">
                    <div className="col-md-6">
                      <h4 className="card-title">English </h4>
                      <hr className="border-2" />
                      <div className="mb-3">
                        <label className="form-label">Heading</label>
                        <textarea
                          type="text"
                          className="form-control"
                          value={formsFieldsEnglish.heading}
                          onChange={(e) =>
                            setformsFieldsEnglish({
                              ...formsFieldsEnglish,
                              heading: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <h4 className="card-title">Nepali </h4>
                      <hr className="border-2" />
                      <div className="mb-3">
                        <label className="form-label">Heading</label>
                        <textarea
                          type="text"
                          className="form-control"
                          value={formsFieldsNepali.heading}
                          onChange={(e) =>
                            setformsFieldsNepali({
                              ...formsFieldsNepali,
                              heading: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                  </div>

                  {formsFieldsEnglish.approach.map((approach, index) => (
                    <>
                      <div key={index} className="parameters-container">
                        <div
                          className="parameters-header"
                          onClick={() => toggleCollapse(index)}
                        >
                          <span className="parameters-title">
                            {index + 1}. OurApproach
                          </span>
                          <div className="icon-containers">
                            <button className="parameters-toggle-btn">
                              <i
                                onClick={handleAddOurApproach}
                                class="bi bi-plus-lg"
                              ></i>
                            </button>
                            <button className="parameters-toggle-btn">
                              <i
                                onClick={() =>
                                  handleRemoveOurApproach(
                                    index,
                                    formsFieldsNepali.approach[index],
                                    approach
                                  )
                                }
                                class="bi bi-trash3"
                              ></i>
                            </button>
                            <button
                              className="parameters-toggle-btn"
                              id={`toggleButton${index}`}
                            >
                              {isCollapsed[index] ? "\u25BC" : "\u25B2"}
                            </button>
                          </div>
                        </div>
                        <div
                          className="parameters-body"
                          id={`parametersBody${index}`}
                          style={{
                            display: isCollapsed[index] ? "none" : "block",
                          }}
                        >
                          <div className=" border border-dark rounded">
                            <div className="d-flex mt-3 container">
                              <h5 className="card-title">
                                {index + 1}. OurApproach
                              </h5>
                              <hr className="border-2" />
                              <div className=" justify-content-end">
                                <button
                                  onClick={(e) =>
                                    handleSaveOurApproach(index, e)
                                  }
                                  className="btn btn-primary"
                                >
                                  Save
                                </button>
                              </div>
                            </div>
                            <div className=" row m-2 ">
                              <div className="col-md-6 mb-3">
                                <label className="form-label">Image (Best Fit Size: height: 70px; width: 70px;)</label>
                                <input
                                  type="file"
                                  onChange={(e) => handleImageChange(index, e)}
                                />
                              </div>

                              <div className=" mb-3 col-md-6">
                                <img
                                  style={{
                                    height: "200px",
                                    width: "300px",
                                    objectFit: "contain",
                                  }}
                                  src={approach.image}
                                  alt="Uploaded Photograph"
                                />
                              </div>

                              <div className="col-md-6">
                                <h4 className="card-title">English </h4>
                                <hr className="border-2" />
                                <div className=" mb-3">
                                  <label className="form-label">Title</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={approach.title}
                                    name="title"
                                    onChange={(e) =>
                                      handleEnglishInputChange(index, e)
                                    }
                                  />
                                </div>
                              </div>

                              <div className="col-md-6">
                                <h4 className="card-title">Nepali </h4>
                                <hr className="border-2" />
                                <div className="mb-3">
                                  <label className="form-label">Title</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={
                                      formsFieldsNepali.approach[index].title
                                    }
                                    name="title"
                                    onChange={(e) =>
                                      handleNepaliInputChange(index, {
                                        target: {
                                          name: "title",
                                          value: e.target.value,
                                        },
                                      })
                                    }
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="m-3 d-flex justify-content-end  ">
                              <button
                                onClick={() =>
                                  handleRemoveOurApproach(
                                    index,
                                    formsFieldsNepali.approach[index],
                                    approach
                                  )
                                }
                                className="btn btn-primary"
                              >
                                Remove OurApproach
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>

                      <br />
                    </>
                  ))}

                  <div className="mt-3 mb-3 d-flex justify-content-end">
                    <button
                      className="btn btn-primary"
                      onClick={handleAddOurApproach}
                    >
                      Add OurApproach
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <ToastContainer />
      </main>
    </>
  );
};

export default AboutUsOurApproach;
