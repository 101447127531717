import React, { useEffect, useState } from "react";
import Accessibility from "./Accessibility";
import { getAboutUsHistory, getBannerImages } from "../../services/api";
import coverImage from "../../assets/img/cover.jpg";

const AboutUsHistory = () => {
  const [aboutUsImages, setAboutUsImages] = useState({
    aboutUsHistory: "",
    aboutUsIntroduction: "",
    aboutUsOurTeam: "",
  });

  const [history, setHistory] = useState({});

  const [currentLocale, setcurrentLocale] = useState("EN");

  const fetchData = async () => {
    try {
      const res = await getBannerImages();
      const resData = res.data;

      let aboutusData = {
        aboutUsHistory: "",
        aboutUsIntroduction: "",
        aboutUsOurTeam: "",
      };

      aboutusData.aboutUsHistory = resData.aboutUsHistory;
      aboutusData.aboutUsIntroduction = resData.aboutUsIntroduction;
      aboutusData.aboutUsOurTeam = resData.aboutUsOurTeam;
      setAboutUsImages(aboutusData);
      console.log(aboutUsImages);
    } catch (error) {}
  };

  const fetchHistoryData = async () => {
    try {
      const res = await getAboutUsHistory();
      if (res.data) {
        setHistory(res.data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    fetchData();
    fetchHistoryData();
    let currentLocale = localStorage.getItem("locale")
      ? localStorage.getItem("locale") === "eng"
        ? "EN"
        : "NP"
      : "EN";
    setcurrentLocale(currentLocale);
  }, [currentLocale]);
  return (
    <>
      <main>
        <Accessibility />

        <section className="about-us-section">
          <div
            className="banner"
            style={{
              backgroundImage: `url(${
                aboutUsImages && aboutUsImages.aboutUsHistory
                  ? aboutUsImages.aboutUsHistory
                  : coverImage
              })`,
            }}
          >
            <div className="banner-content">
              <h1 className="text-white text-center">
                {currentLocale === "EN" ? "Our History" : "हाम्रो इतिहास"}
              </h1>
            </div>
          </div>
          <div className="px-5 py-3" style={{ backgroundColor: "#d1dbeb" }}>
            <p>
              {history && history.historyDescription
                ? history.historyDescription
                : "No Data"}
            </p>
          </div>
          <div className="row no-gutters">
            {history.history
              ? history.history.map((item, index) => (
                  <React.Fragment key={index}>
                    {index % 2 === 0 ? (
                      <>
                        <div className="col-12 col-md-6">
                          <div className="px-5 py-3">
                            <h1
                              className="text-blue"
                              style={{ fontSize: "3.5rem" }}
                            >
                              {item.title}
                            </h1>
                            <h3 className="text-blue">{item.subTitle}</h3>
                            <p>{item.description}</p>
                          </div>
                        </div>
                        <div className="col-12 col-md-6">
                          <div className="about-us-img-container">
                            <img
                              src={item.image}
                              alt="Our History 8"
                              className="img-fluid about-us-img"
                            />
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="col-12 col-md-6">
                          <div className="about-us-img-container">
                            <img
                              src={item.image}
                              alt="Our History"
                              className="img-fluid about-us-img"
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6">
                          <div className="px-5 py-3">
                            <h1
                              className="text-blue"
                              style={{ fontSize: "3.5rem" }}
                            >
                              {item.title}
                            </h1>
                            <h3 className="text-blue">{item.subTitle}</h3>
                            <p>{item.description}</p>
                          </div>
                        </div>
                      </>
                    )}
                  </React.Fragment>
                ))
              : "No Data"}
          </div>
        </section>
      </main>
    </>
  );
};

export default AboutUsHistory;
