import React, { useEffect, useState } from "react";
import { toastError, toastSuccess } from "../../../../services/ToastService";
import { ToastContainer } from "react-toastify";
import {
  deleteEnglishThematicArea,
  deleteNepaliThematicArea,
  getAllThematicArea,
  saveEnglishThematicArea,
  saveEnglishThematicAreaHeading,
  saveNepaliThematicArea,
  saveNepaliThematicAreaHeading,
} from "../../../../services/api";

const AboutUsThematicAreas = () => {
  const [isCollapsed, setIsCollapsed] = useState([]);
  const [formsFieldsEnglish, setformsFieldsEnglish] = useState({
    heading: "",
    thematicAreas: [
      {
        _id: "",
        image: "",
        title: "",
        description: "",
      },
    ],
  });

  const [formsFieldsNepali, setformsFieldsNepali] = useState({
    heading: "",
    thematicAreas: [
      {
        _id: "",
        image: "",
        title: "",
        description: "",
      },
    ],
  });

  const getallThematicAreas = async () => {
    try {
      let res = await getAllThematicArea();
      if (res.data) {
        res.data.forEach((item) => {
          if (item.locale === "eng") {
            setformsFieldsEnglish((prevState) => ({
              ...prevState,
              heading: item.heading,
              thematicAreas: item.thematicAreas,
            }));
          } else if (item.locale === "nep") {
            setformsFieldsNepali((prevState) => ({
              ...prevState,
              heading: item.heading,
              thematicAreas: item.thematicAreas,
            }));
          }
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleEnglishInputChange = (index, event) => {
    const { name, value } = event.target;
    setformsFieldsEnglish((prevState) => {
      const updatedThematicAreas = [...prevState.thematicAreas];
      updatedThematicAreas[index][name] = value;
      return { ...prevState, thematicAreas: updatedThematicAreas };
    });
  };

  const handleNepaliInputChange = (index, event) => {
    const { name, value } = event.target;
    setformsFieldsNepali((prevState) => {
      const updatedThematicAreas = [...prevState.thematicAreas];
      updatedThematicAreas[index][name] = value;
      return { ...prevState, thematicAreas: updatedThematicAreas };
    });
  };

  const handleAddThematicAreas = () => {
    setformsFieldsEnglish({
      ...formsFieldsEnglish,
      thematicAreas: [
        ...formsFieldsEnglish.thematicAreas,
        {
          _id: "",
          image: "",
          title: "",
          description: "",
        },
      ],
    });

    setformsFieldsNepali({
      ...formsFieldsNepali,
      thematicAreas: [
        ...formsFieldsNepali.thematicAreas,
        {
          _id: "",
          image: "",
          title: "",
          description: "",
        },
      ],
    });
  };

  const handleRemoveThematicAreas = async (
    index,
    nepaliThematicAreas,
    enslishThematicAreas
  ) => {
    if (
      nepaliThematicAreas._id &&
      nepaliThematicAreas._id !== "" &&
      enslishThematicAreas._id &&
      enslishThematicAreas._id !== ""
    ) {
      await deleteNepaliThematicArea(nepaliThematicAreas._id);
      await deleteEnglishThematicArea(enslishThematicAreas._id);

      removeThematicAreas(index);
    } else {
      removeThematicAreas(index);
    }
  };

  const removeThematicAreas = (index) => {
    const updatedEnglishThematicAreas = [...formsFieldsEnglish.thematicAreas];
    updatedEnglishThematicAreas.splice(index, 1);
    setformsFieldsEnglish({
      ...formsFieldsEnglish,
      thematicAreas: updatedEnglishThematicAreas,
    });

    const updatedNepaliThematicAreas = [...formsFieldsNepali.thematicAreas];
    updatedNepaliThematicAreas.splice(index, 1);
    setformsFieldsNepali({
      ...formsFieldsNepali,
      thematicAreas: updatedNepaliThematicAreas,
    });
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    try {
      if (formsFieldsEnglish) {
        let data = {
          heading: formsFieldsEnglish.heading,
        };
        await saveEnglishThematicAreaHeading(data);
      }

      if (formsFieldsNepali) {
        let data = {
          heading: formsFieldsNepali.heading,
        };
        await saveNepaliThematicAreaHeading(data);
      }

      toastSuccess();
    } catch (e) {
      toastError();
      console.log(e);
    }
  };

  const handleImageChange = (index, event) => {
    const file = event.target.files[0];

    setformsFieldsEnglish((prevState) => {
      const updatedThematicAreas = [...prevState.thematicAreas];
      updatedThematicAreas[index].image = file;
      return { ...prevState, thematicAreas: updatedThematicAreas };
    });

    setformsFieldsNepali((prevState) => {
      const updatedThematicAreas = [...prevState.thematicAreas];
      updatedThematicAreas[index].image = file;
      return { ...prevState, thematicAreas: updatedThematicAreas };
    });
  };

  useEffect(() => {
    setIsCollapsed(
      new Array(formsFieldsEnglish.thematicAreas.length).fill(true)
    );
  }, [formsFieldsEnglish.thematicAreas.length]);

  useEffect(() => {
    getallThematicAreas();
  }, []);

  const toggleCollapse = (index) => {
    setIsCollapsed((prevState) =>
      prevState.map((state, i) => (i === index ? !state : state))
    );
  };

  const handleSaveThematicAreas = async (index, e) => {
    e.preventDefault();
    try {
      if (formsFieldsEnglish) {
        const formData = new FormData();
        formData.append("title", formsFieldsEnglish.thematicAreas[index].title);
        formData.append("image", formsFieldsEnglish.thematicAreas[index].image);
        formData.append(
          "description",
          formsFieldsEnglish.thematicAreas[index].description
        );
        formData.append("id", formsFieldsEnglish.thematicAreas[index]._id);
        await saveEnglishThematicArea(formData);
      }

      if (formsFieldsNepali) {
        const formData = new FormData();
        formData.append("title", formsFieldsNepali.thematicAreas[index].title);
        formData.append("image", formsFieldsNepali.thematicAreas[index].image);
        formData.append(
          "description",
          formsFieldsNepali.thematicAreas[index].description
        );
        formData.append("id", formsFieldsNepali.thematicAreas[index]._id);
        await saveNepaliThematicArea(formData);
      }
      toastSuccess();
    } catch (error) {
      console.log(error);
      toastError();
    }
  };

  return (
    <>
      <main className="main">
        <div className="pagetitle">
          <h1>ThematicAreas</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="index.html">Auth</a>
              </li>
              <li className="breadcrumb-item">Components</li>
              <li className="breadcrumb-item active">ThematicAreas</li>
            </ol>
          </nav>
        </div>
        <section className="section">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="mt-3 mb-3 d-flex justify-content-end">
                    <button
                      type="submit"
                      onClick={onSubmit}
                      className="btn btn-primary"
                    >
                      Save
                    </button>
                  </div>
                  <div className="row ">
                    <div className="col-md-6">
                      <h4 className="card-title">English </h4>
                      <hr className="border-2" />
                      <div className="mb-3">
                        <label className="form-label">Heading</label>
                        <textarea
                          type="text"
                          className="form-control"
                          value={formsFieldsEnglish.heading}
                          onChange={(e) =>
                            setformsFieldsEnglish({
                              ...formsFieldsEnglish,
                              heading: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <h4 className="card-title">Nepali </h4>
                      <hr className="border-2" />
                      <div className="mb-3">
                        <label className="form-label">Heading</label>
                        <textarea
                          type="text"
                          className="form-control"
                          value={formsFieldsNepali.heading}
                          onChange={(e) =>
                            setformsFieldsNepali({
                              ...formsFieldsNepali,
                              heading: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                  </div>

                  {formsFieldsEnglish.thematicAreas.map(
                    (thematicAreas, index) => (
                      <>
                        <div key={index} className="parameters-container">
                          <div
                            className="parameters-header"
                            onClick={() => toggleCollapse(index)}
                          >
                            <span className="parameters-title">
                              {index + 1}. ThematicAreas
                            </span>
                            <div className="icon-containers">
                              <button className="parameters-toggle-btn">
                                <i
                                  onClick={handleAddThematicAreas}
                                  class="bi bi-plus-lg"
                                ></i>
                              </button>
                              <button className="parameters-toggle-btn">
                                <i
                                  onClick={() =>
                                    handleRemoveThematicAreas(
                                      index,
                                      formsFieldsNepali.thematicAreas[index],
                                      thematicAreas
                                    )
                                  }
                                  class="bi bi-trash3"
                                ></i>
                              </button>
                              <button
                                className="parameters-toggle-btn"
                                id={`toggleButton${index}`}
                              >
                                {isCollapsed[index] ? "\u25BC" : "\u25B2"}
                              </button>
                            </div>
                          </div>
                          <div
                            className="parameters-body"
                            id={`parametersBody${index}`}
                            style={{
                              display: isCollapsed[index] ? "none" : "block",
                            }}
                          >
                            <div className=" border border-dark rounded">
                              <div className="d-flex mt-3 container">
                                <h5 className="card-title">
                                  {index + 1}. ThematicAreas
                                </h5>
                                <hr className="border-2" />
                                <div className=" justify-content-end">
                                  <button
                                    onClick={(e) =>
                                      handleSaveThematicAreas(index, e)
                                    }
                                    className="btn btn-primary"
                                  >
                                    Save
                                  </button>
                                </div>
                              </div>
                              <div className=" row m-2 ">
                                <div className="col-md-6 mb-3">
                                  <label className="form-label">Image (Best Fit Size: height: 70px; width: 70px;)</label>
                                  <input
                                    type="file"
                                    onChange={(e) =>
                                      handleImageChange(index, e)
                                    }
                                  />
                                </div>

                                <div className=" mb-3 col-md-6">
                                  <img
                                    style={{
                                      height: "200px",
                                      width: "300px",
                                      objectFit: "contain",
                                    }}
                                    src={thematicAreas.image}
                                    alt="Uploaded Photograph"
                                  />
                                </div>

                                <div className="col-md-6">
                                  <h4 className="card-title">English </h4>
                                  <hr className="border-2" />
                                  <div className=" mb-3">
                                    <label className="form-label">Title</label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={thematicAreas.title}
                                      name="title"
                                      onChange={(e) =>
                                        handleEnglishInputChange(index, e)
                                      }
                                    />
                                  </div>

                                  <div className=" mb-3">
                                    <label className="form-label">
                                      Description
                                    </label>
                                    <textarea
                                      type="text"
                                      className="form-control"
                                      value={thematicAreas.description}
                                      name="description"
                                      onChange={(e) =>
                                        handleEnglishInputChange(index, e)
                                      }
                                    />
                                  </div>
                                </div>

                                <div className="col-md-6">
                                  <h4 className="card-title">Nepali </h4>
                                  <hr className="border-2" />
                                  <div className="mb-3">
                                    <label className="form-label">Title</label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={
                                        formsFieldsNepali.thematicAreas[index]
                                          .title
                                      }
                                      name="title"
                                      onChange={(e) =>
                                        handleNepaliInputChange(index, {
                                          target: {
                                            name: "title",
                                            value: e.target.value,
                                          },
                                        })
                                      }
                                    />
                                  </div>

                                  <div className="mb-3">
                                    <label className="form-label">
                                      Description
                                    </label>
                                    <textarea
                                      type="text"
                                      className="form-control"
                                      value={
                                        formsFieldsNepali.thematicAreas[index]
                                          .description
                                      }
                                      name="description"
                                      onChange={(e) =>
                                        handleNepaliInputChange(index, {
                                          target: {
                                            name: "description",
                                            value: e.target.value,
                                          },
                                        })
                                      }
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="m-3 d-flex justify-content-end  ">
                                <button
                                  onClick={() =>
                                    handleRemoveThematicAreas(
                                      index,
                                      formsFieldsNepali.thematicAreas[index],
                                      thematicAreas
                                    )
                                  }
                                  className="btn btn-primary"
                                >
                                  Remove ThematicAreas
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>

                        <br />
                      </>
                    )
                  )}

                  <div className="mt-3 mb-3 d-flex justify-content-end">
                    <button
                      className="btn btn-primary"
                      onClick={handleAddThematicAreas}
                    >
                      Add ThematicAreas
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <ToastContainer />
      </main>
    </>
  );
};

export default AboutUsThematicAreas;
