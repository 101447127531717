import React, { useEffect, useState } from "react";
import { getOurwork } from "../../../services/api";
import { useParams } from "react-router-dom";

function OurWorkLearnMore() {
  const [ourWork, setOurWork] = useState("");

  let { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getOurwork();
        setTimeout(() => {
          if (res.data) {
            let individualWork = res.data.work.find(
              (item) => item._id.toString() === id.toString()
            );
            if (individualWork) {
              setOurWork(individualWork);
            } else {
              setOurWork(res.data.work[0]);
            }
          }
        });
      } catch (error) {}
    };

    fetchData();
  }, []);
  return (
    <>
      <section>
        <div>
          <div dangerouslySetInnerHTML={{ __html: ourWork.details }} />
        </div>
      </section>
    </>
  );
}

export default OurWorkLearnMore;
