import React, { useEffect, useState } from "react";
import Accessibility from "./Accessibility";
import {
  getAboutUsImages,
  getAboutUsIntroduction,
  getAboutUsOurApproach,
  getAboutUsOurValues,
  getAboutUsThematicArea,
  getBannerImages,
} from "../../services/api";
import coverImage from "../../assets/img/cover.jpg";
import OurImpacts from "./OurImpacts";
import OurWork from "./OurWork";

const AboutUsIntroduction = () => {
  const [aboutUsImages, setAboutUsImages] = useState({
    aboutUsHistory: "",
    aboutUsIntroduction: "",
    aboutUsOurTeam: "",
  });

  const [introduction, setIntroduction] = useState({});
  const [thematicArea, setThematicArea] = useState({});
  const [ourValues, setOurValues] = useState({});
  const [ourApproach, setOurApproach] = useState({});

  const [currentLocale, setcurrentLocale] = useState("EN");

  const fetchData = async () => {
    try {
      const res = await getBannerImages();
      const resData = res.data;

      let aboutusData = {
        aboutUsHistory: "",
        aboutUsIntroduction: "",
        aboutUsOurTeam: "",
      };

      aboutusData.aboutUsHistory = resData.aboutUsHistory;
      aboutusData.aboutUsIntroduction = resData.aboutUsIntroduction;
      aboutusData.aboutUsOurTeam = resData.aboutUsOurTeam;
      setAboutUsImages(aboutusData);
    } catch (error) {}
  };

  const fetchIntroductionData = async () => {
    try {
      const res = await getAboutUsIntroduction();
      if (res.data) {
        setIntroduction(res.data);
      }
      const thematicData = await getAboutUsThematicArea();
      if (thematicData.data) {
        setThematicArea(thematicData.data);
      }

      const ourValuesData = await getAboutUsOurValues();
      if (ourValuesData.data) {
        setOurValues(ourValuesData.data);
      }

      const ourApproachData = await getAboutUsOurApproach();
      if (ourApproachData.data) {
        setOurApproach(ourApproachData.data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    fetchData();
    fetchIntroductionData();
    let currentLocale = localStorage.getItem("locale")
      ? localStorage.getItem("locale") === "eng"
        ? "EN"
        : "NP"
      : "EN";
    setcurrentLocale(currentLocale);
  }, []);
  return (
    <>
      <main>
        <Accessibility />

        <section className="about-us-section">
          <div
            className="banner"
            style={{
              backgroundImage: `url(${
                aboutUsImages && aboutUsImages.aboutUsIntroduction
                  ? aboutUsImages.aboutUsIntroduction
                  : coverImage
              })`,
            }}
          >
            <div className="banner-content">
              <h1 className="text-white text-center">
                {currentLocale === "EN" ? "Our Introduction" : "हाम्रो परिचय"}
              </h1>
            </div>
          </div>

          <div className="container my-3">
            <div className="row">
              <div className="col-12">
                <h3 className="text-center mt-3">
                  {introduction.introductionTitle}
                  {/* {currentLocale === "EN" ? "Our Introduction" : "हाम्रो परिचय"} */}
                </h3>
                <p>{introduction.introductionDescription}</p>
              </div>
              <div className="col-12 col-md-6 mt-5">
                <h1 className="text-blue text-uppercase fst-italic">
                  {introduction.imageTitle && (
                    <>
                      {introduction.imageTitle
                        .split(" ")
                        .slice(0, -2)
                        .join(" ")}{" "}
                      <br />
                      <strong>
                        {introduction.imageTitle.split(" ").slice(-2).join(" ")}
                      </strong>
                    </>
                  )}
                </h1>
              </div>
              <div className="col-12 col-md-6">
                <h3 className="text-right mt-3">
                  {introduction.ourVisionTitle}
                  {/* {currentLocale === "EN"
                    ? "Our Vision"
                    : "हाम्रो दृष्टिबित्ति"} */}
                </h3>
                <p className="text-right">
                  {introduction.ourVisionDescription}
                </p>
                <br />
                <h3 className="text-right mt-3">
                  {introduction.ourMissionTitle}
                  {/* {currentLocale === "EN" ? "Our Mission" : "हाम्रो मिशन"} */}
                </h3>
                <p className="text-right">
                  {introduction.ourMissionDescription}
                </p>
                <br />
                <h3 className="text-right mt-3">
                  {introduction.ourGoalTitle}
                  {/* {currentLocale === "EN" ? "Our Goal" : "हाम्रो लक्ष्य"} */}
                </h3>
                <p className="text-right">{introduction.ourGoalDescription}</p>
              </div>
            </div>
            <h3 className="text-center mt-5">
              {currentLocale === "EN"
                ? "Our Thematic Areas"
                : "हाम्रा विषय वस्तुका क्षेत्रहरू"}
            </h3>
            <div className="row">
              {thematicArea.thematicAreas
                ? thematicArea.thematicAreas.map((item, index) => (
                    <div key={index} className="col-12 col-md-6 col-xl-3">
                      <div className="card p-3 mt-3 d-flex flex-column align-items-center about-us-fixed-height">
                        <img
                          src={item.image}
                          alt="Advocacy Icon"
                          className="thematic-icons"
                        />
                        <h4 className="text-center text-blue ">{item.title}</h4>
                        <p>{item.description}</p>
                      </div>
                    </div>
                  ))
                : "No Data"}
            </div>
            <h3 className="text-center mt-5">
              {currentLocale === "EN" ? "Our Values" : "हाम्रो मूल्यहरू"}{" "}
            </h3>
            <div className="row">
              {ourValues.values
                ? ourValues.values.map((item, index) => (
                    <div key={index} className="col-6 col-md-3">
                      <div className="p-3 mt-3 d-flex flex-column align-items-center about-us-fixed-height">
                        <img
                          src={item.image}
                          alt="Advocacy Icon"
                          className="thematic-icons"
                        />
                        <h4 className="text-center text-blue ">{item.title}</h4>
                      </div>
                    </div>
                  ))
                : "No Data"}
            </div>

            <h3 className="text-center mt-5">
              {" "}
              {currentLocale === "EN" ? "Our Approach" : "हाम्रो अवस्थापन"}
            </h3>
            <div className="row">
              {ourApproach.approach
                ? ourApproach.approach.map((item, index) => (
                    <div key={index} className="col-6 col-md-3">
                      <div className="p-3 mt-3 d-flex flex-column align-items-center about-us-fixed-height">
                        <img
                          src={item.image}
                          alt="Advocacy Icon"
                          className="thematic-icons"
                        />
                        <h4 className="text-center text-blue ">{item.title}</h4>
                      </div>
                    </div>
                  ))
                : "No Data"}
            </div>
          </div>
        </section>

        <OurImpacts />

        <section className="my-5">
          <OurWork />
        </section>
      </main>
    </>
  );
};

export default AboutUsIntroduction;
