import React, { useEffect, useState } from "react";
import { getStories } from "../../../services/storiesService";
import { useParams } from "react-router-dom";

function OurStoriesReadMore() {
  const [story, setStory] = useState(null);
  const [locale, setLocale] = useState("eng");
  let { id } = useParams();

  useEffect(() => {
    const fetchStory = async () => {
      try {
        const response = await getStories();
        const allStories = response.data.data.contents;
        const individualStory = allStories.find(
          (item) => item._id.toString() === id.toString()
        );
        setStory(individualStory || allStories[0]);
      } catch (error) {
        console.error("Error fetching story:", error);
      }
    };

    let locale = localStorage.getItem("locale")
      ? localStorage.getItem("locale")
      : "eng";
    setLocale(locale);
    fetchStory();
  }, [id]);

  if (!story) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <section className="testimonials">
        <div className="container">
          <div className="row mt-5">
            <div
              dangerouslySetInnerHTML={{
                __html: locale === "eng" ? story.detail : story.detailNepali,
              }}
            />
          </div>
        </div>
      </section>
    </>
  );
}

export default OurStoriesReadMore;
