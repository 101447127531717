import JoditEditor from "jodit-react";
import React, { useEffect, useRef, useState } from "react";
import { englishConfig } from "../../../../services/joditConfigService";

import { toastError, toastSuccess } from "../../../../services/ToastService";
import { ToastContainer } from "react-toastify";
import {
  deleteEnglishVolenteer,
  deleteNepaliVolenteer,
  getAllVolenteer,
  saveEnglishVolenteer,
  saveNepaliVolenteer,
} from "../../../../services/api";

const GetInvolvedVolenteer = () => {
  const editorRef = useRef(null);
  const [isCollapsed, setIsCollapsed] = useState([]);
  const [formsFieldsEnglish, setformsFieldsEnglish] = useState({
    volenteer: [
      {
        _id: "",
        name: "",
        content: "",
      },
    ],
  });

  const [formsFieldsNepali, setformsFieldsNepali] = useState({
    volenteer: [
      {
        _id: "",
        name: "",
        content: "",
      },
    ],
  });

  const getAllvolenteer = async () => {
    try {
      let res = await getAllVolenteer();
      if (res.data) {
        res.data.forEach((item) => {
          if (item.locale === "eng") {
            setformsFieldsEnglish((prevState) => ({
              ...prevState,
              volenteer: item.volenteer,
            }));
          } else if (item.locale === "nep") {
            setformsFieldsNepali((prevState) => ({
              ...prevState,
              volenteer: item.volenteer,
            }));
          }
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleEnglishInputChange = (index, event) => {
    const { name, value } = event.target;
    setformsFieldsEnglish((prevState) => {
      const updatedVolenteer = [...prevState.volenteer];
      updatedVolenteer[index][name] = value;
      return { ...prevState, volenteer: updatedVolenteer };
    });
  };

  const handleNepaliInputChange = (index, event) => {
    const { name, value } = event.target;
    setformsFieldsNepali((prevState) => {
      const updatedVolenteer = [...prevState.volenteer];
      updatedVolenteer[index][name] = value;
      return { ...prevState, volenteer: updatedVolenteer };
    });
  };

  const handleAddvolenteer = () => {
    setformsFieldsEnglish({
      ...formsFieldsEnglish,
      volenteer: [
        ...formsFieldsEnglish.volenteer,
        {
          _id: "",
          name: "",
          content: "",
        },
      ],
    });

    setformsFieldsNepali({
      ...formsFieldsNepali,
      volenteer: [
        ...formsFieldsNepali.volenteer,
        {
          _id: "",
          name: "",
          content: "",
        },
      ],
    });
  };

  const handleRemovevolenteer = async (
    index,
    nepalivolenteer,
    englishvolenteer
  ) => {
    if (
      nepalivolenteer._id &&
      nepalivolenteer._id !== "" &&
      englishvolenteer._id &&
      englishvolenteer._id !== ""
    ) {
      await deleteNepaliVolenteer(nepalivolenteer._id);
      await deleteEnglishVolenteer(englishvolenteer._id);

      removeVolenteer(index);
    } else {
      removeVolenteer(index);
    }
  };

  const removeVolenteer = (index) => {
    const updatedEnglishVolenteer = [...formsFieldsEnglish.volenteer];
    updatedEnglishVolenteer.splice(index, 1);
    setformsFieldsEnglish({
      ...formsFieldsEnglish,
      volenteer: updatedEnglishVolenteer,
    });

    const updatedNepaliVolenteer = [...formsFieldsNepali.volenteer];
    updatedNepaliVolenteer.splice(index, 1);
    setformsFieldsNepali({
      ...formsFieldsNepali,
      volenteer: updatedNepaliVolenteer,
    });
  };

  useEffect(() => {
    setIsCollapsed(new Array(formsFieldsEnglish.volenteer.length).fill(true));
  }, [formsFieldsEnglish.volenteer.length]);

  useEffect(() => {
    getAllvolenteer();
  }, []);

  const toggleCollapse = (index) => {
    setIsCollapsed((prevState) =>
      prevState.map((state, i) => (i === index ? !state : state))
    );
  };

  const handleSaveVolenteer = async (index, e) => {
    e.preventDefault();
    try {
      if (formsFieldsEnglish) {
        const formData = new FormData();
        formData.append("name", formsFieldsEnglish.volenteer[index].name);
        formData.append("content", formsFieldsEnglish.volenteer[index].content);
        formData.append("id", formsFieldsEnglish.volenteer[index]._id);

        await saveEnglishVolenteer(formData);
      }

      if (formsFieldsNepali) {
        const formData = new FormData();
        formData.append("name", formsFieldsNepali.volenteer[index].name);
        formData.append("content", formsFieldsNepali.volenteer[index].content);
        formData.append("id", formsFieldsNepali.volenteer[index]._id);

        await saveNepaliVolenteer(formData);
      }
      toastSuccess();
    } catch (error) {
      console.log(error);
      toastError();
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body">
              <div className="pagetitle">
                <h3>Volenteer</h3>
              </div>
              {formsFieldsEnglish.volenteer.map((vacancies, index) => (
                <React.Fragment key={index}>
                  <div className="parameters-container">
                    <div
                      className="parameters-header"
                      onClick={() => toggleCollapse(index)}
                    >
                      <span className="parameters-title">
                        {index + 1}. Volenteer
                      </span>
                      <div className="icon-containers">
                        <button className="parameters-toggle-btn">
                          <i
                            onClick={handleAddvolenteer}
                            class="bi bi-plus-lg"
                          ></i>
                        </button>
                        <button className="parameters-toggle-btn">
                          <i
                            onClick={() =>
                              handleRemovevolenteer(
                                index,
                                formsFieldsNepali.volenteer[index],
                                vacancies
                              )
                            }
                            class="bi bi-trash3"
                          ></i>
                        </button>
                        <button
                          className="parameters-toggle-btn"
                          id={`toggleButton${index}`}
                        >
                          {isCollapsed[index] ? "\u25BC" : "\u25B2"}
                        </button>
                      </div>
                    </div>
                    <div
                      className="parameters-body"
                      id={`parametersBody${index}`}
                      style={{
                        display: isCollapsed[index] ? "none" : "block",
                      }}
                    >
                      <div className=" border border-dark rounded">
                        <div className="d-flex mt-3 container">
                          <h5 className="card-title">{index + 1}. Volenteer</h5>
                          <hr className="border-2" />
                          <div className=" justify-content-end">
                            <button
                              onClick={(e) => handleSaveVolenteer(index, e)}
                              className="btn btn-primary"
                            >
                              Save
                            </button>
                          </div>
                        </div>
                        <div className=" row m-2 ">
                          <div className="col-md-6">
                            <h4 className="card-title">English </h4>
                            <hr className="border-2" />
                            <div className=" mb-3">
                              <label className="form-label">Name</label>
                              <input
                                type="text"
                                className="form-control"
                                value={vacancies.name}
                                name="name"
                                onChange={(e) =>
                                  handleEnglishInputChange(index, e)
                                }
                              />
                            </div>

                            <div className=" mb-3">
                              <JoditEditor
                                ref={editorRef}
                                tabIndex={1}
                                value={vacancies.content}
                                config={englishConfig}
                                onChange={(content) =>
                                  handleEnglishInputChange(index, {
                                    target: {
                                      name: "content",
                                      value: content,
                                    },
                                  })
                                }
                              />
                            </div>
                          </div>

                          <div className="col-md-6">
                            <h4 className="card-title">Nepali </h4>
                            <hr className="border-2" />
                            <div className="mb-3">
                              <label className="form-label">Name</label>
                              <input
                                type="text"
                                className="form-control"
                                value={formsFieldsNepali.volenteer[index].name}
                                name="name"
                                onChange={(e) =>
                                  handleNepaliInputChange(index, {
                                    target: {
                                      name: "name",
                                      value: e.target.value,
                                    },
                                  })
                                }
                              />
                            </div>

                            <div className=" mb-3">
                              <JoditEditor
                                ref={editorRef}
                                tabIndex={1}
                                value={
                                  formsFieldsNepali.volenteer[index].content
                                }
                                config={englishConfig}
                                onChange={(content) =>
                                  handleNepaliInputChange(index, {
                                    target: {
                                      name: "content",
                                      value: content,
                                    },
                                  })
                                }
                              />
                            </div>
                          </div>
                        </div>

                        <div className="m-3 d-flex justify-content-end  ">
                          <button
                            onClick={() =>
                              handleRemovevolenteer(
                                index,
                                formsFieldsNepali.volenteer[index],
                                vacancies
                              )
                            }
                            className="btn btn-primary"
                          >
                            Remove Work
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <br />
                </React.Fragment>
              ))}

              <div className="mt-3 mb-3 d-flex justify-content-end">
                <button
                  className="btn btn-primary"
                  onClick={handleAddvolenteer}
                >
                  Add Work
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer />
    </>
  );
};

export default GetInvolvedVolenteer;
