import React, { useEffect, useState } from "react";
import { getResourcesData } from "../../services/resourcesService";
import { useParams } from "react-router-dom";
import { getBannerImages, getResourcesImages } from "../../services/api";
import coverImage from "../../assets/img/cover.jpg";
import Accessibility from "./Accessibility";
import LatestNewsViewAll from "./homePageRoutingSection/LatestNewsViewAll";
import PublicationHome from "./PublicationHome";
import BlogViewAll from "./homePageRoutingSection/BlogViewAll";

export const ResourcesHome = () => {
  const [media, setMedia] = useState("");
  const [newsAndPressRelease, setNewsAndPressRelease] = useState("");
  const [events, setEvents] = useState("");
  const [digitalLibrary, setDigitalLibrary] = useState("");
  const [transformingLives, setTransformingLives] = useState("");
  const [blog, setBlog] = useState("");
  const [locale, setLocale] = useState("nep");
  let { fieldName } = useParams();
  const [currentLocale, setcurrentLocale] = useState("EN");
  const [navbarImages, setNavBarImages] = useState();

  const stateDatas = {
    media: setMedia,
    newsAndPressRelease: setNewsAndPressRelease,
    events: setEvents,
    digitalLibrary: setDigitalLibrary,
    transformingLives: setTransformingLives,
    blog: setBlog,
  };

  const stateKeys = Object.keys(stateDatas);

  useEffect(() => {
    let currentLocale = localStorage.getItem("locale")
      ? localStorage.getItem("locale") === "eng"
        ? "EN"
        : "NP"
      : "EN";
    setcurrentLocale(currentLocale);
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const res = await getBannerImages();
      const resData = res.data;
      if (resData) {
        setNavBarImages(resData);
      }
    } catch (error) {}
  };

  useEffect(() => {
    let Locale = localStorage.getItem("locale")
      ? localStorage.getItem("locale") === "eng"
        ? "eng"
        : "nep"
      : "eng";
    setLocale((previous) => ({
      ...previous,
      locale: Locale,
    }));

    async function fetchResourceData(field, locale = "") {
      try {
        const res = await getResourcesData(field + locale);
        const stateData = res.data.data;
        const stateFunc = stateDatas[field];
        stateFunc(stateData[field + locale]);
      } catch (error) {}
    }

    stateKeys.forEach((state) => {
      if (Locale.toLocaleLowerCase() === "eng") {
        fetchResourceData(state);
      } else if (Locale.toLowerCase() === "nep") {
        fetchResourceData(state, "Nepali");
      }
    });
  }, []);

  return (
    <>
      <section className="about-us-section ">
        <Accessibility />
        {fieldName === "vacancy" && (
          <div
            className="banner"
            style={{
              backgroundImage: `url(${
                navbarImages && navbarImages.resourcesVacancy
                  ? navbarImages.resourcesVacancy
                  : coverImage
              })`,
            }}
          >
            <div className="banner-content">
              <h1 className="text-white text-center">
                {" "}
                {currentLocale === "EN" ? "Media" : "मिडिया"}
              </h1>
            </div>
          </div>
        )}

        {fieldName === "newsAndEvents" && (
          <div
            className="banner"
            style={{
              backgroundImage: `url(${
                navbarImages && navbarImages.resourcesNewsAndEvents
                  ? navbarImages.resourcesNewsAndEvents
                  : coverImage
              })`,
            }}
          >
            <div className="banner-content">
              <h1 className="text-white text-center">
                {" "}
                {currentLocale === "EN" ? "News & " : "समाचार र "}
                {currentLocale === "EN" ? "Events" : "कार्यक्रम"}
              </h1>
            </div>
          </div>
        )}

        {fieldName === "volunteer" && (
          <div
            className="banner"
            style={{
              backgroundImage: `url(${
                navbarImages && navbarImages.resourcesVolunteer
                  ? navbarImages.resourcesVolunteer
                  : coverImage
              })`,
            }}
          >
            <div className="banner-content">
              <h1 className="text-white text-center">
                {" "}
                {currentLocale === "EN" ? "Resources" : "स्रोतहरू"}
              </h1>
            </div>
          </div>
        )}

        {fieldName === "digitalLibrary" && (
          <div
            className="banner"
            style={{
              backgroundImage: `url(${
                navbarImages && navbarImages.resourcesDigitalLibrary
                  ? navbarImages.resourcesDigitalLibrary
                  : coverImage
              })`,
            }}
          >
            <div className="banner-content">
              <h1 className="text-white text-center">
                {" "}
                {currentLocale === "EN"
                  ? "Digital Library"
                  : "डिजिटल पुस्तकालय"}
              </h1>
            </div>
          </div>
        )}

        {fieldName === "lives" && (
          <div
            className="banner"
            style={{
              backgroundImage: `url(${
                navbarImages && navbarImages.resourcesTransformingLives
                  ? navbarImages.resourcesTransformingLives
                  : coverImage
              })`,
            }}
          >
            <div className="banner-content">
              <h1 className="text-white text-center">
                {" "}
                {currentLocale === "EN" ? "Publications" : "प्रकाशन"}
              </h1>
            </div>
          </div>
        )}

        {fieldName === "blog" && (
          <div
            className="banner"
            style={{
              backgroundImage: `url(${
                navbarImages && navbarImages.resourcesBlog
                  ? navbarImages.resourcesBlog
                  : coverImage
              })`,
            }}
          >
            <div className="banner-content">
              <h1 className="text-white text-center">
                {" "}
                {currentLocale === "EN" ? "Blog" : "ब्लग"}
              </h1>
            </div>
          </div>
        )}

        <div className="container">
          <div className="">
            <div className="card-body">
              {fieldName && fieldName === "vacancy" && (
                <>
                  <p dangerouslySetInnerHTML={{ __html: media }}></p>
                </>
              )}

              {fieldName && fieldName === "newsAndEvents" && (
                <>
                  <LatestNewsViewAll />
                  <p
                    className="responsive-div"
                    dangerouslySetInnerHTML={{ __html: newsAndPressRelease }}
                  ></p>

                  <>
                    <p
                      className="responsive-div"
                      dangerouslySetInnerHTML={{ __html: events }}
                    ></p>
                  </>
                </>
              )}

              {fieldName && fieldName === "digitalLibrary" && (
                <>
                  <div>
                    <p dangerouslySetInnerHTML={{ __html: digitalLibrary }}></p>
                  </div>
                </>
              )}

              {fieldName && fieldName === "lives" && (
                <>
                  <PublicationHome />
                </>
              )}

              {fieldName && fieldName === "blog" && (
                <>
                  <BlogViewAll />
                  {/* <div className="responsive-div">
                    <p dangerouslySetInnerHTML={{ __html: blog }}></p>
                  </div> */}
                </>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
