import React, { useEffect, useState } from "react";
import { Button, Table } from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import {
  deleteBoardCommitteeMember,
  getAboutUs,
  updateBoardCommitteeMember,
} from "../../../../services/aboutUsService";

const BoardCommitteeMembers = () => {
  const [members, setMembers] = useState([]);

  const fetchData = async () => {
    try {
      const res = await getAboutUs("boardCommittees");
      setMembers(res.data.data.boardCommittees);
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleChange = (index, field, e) => {
    const updatedMembers = [...members];
    updatedMembers[index][field] = e.target.value;
    setMembers(updatedMembers);
  };

  const handleImageChange = (index, field, event) => {
    const file = event.target.files[0];

    const updatedMembers = [...members];
    updatedMembers[index][field] = file;
    setMembers(updatedMembers);
  };

  const handleSubmit = (index) => async (event) => {
    event.preventDefault();
    const memberToUpdate = members[index];
    let formData = new FormData();
    formData.append("gender", members[index].gender);
    formData.append("name", members[index].name);
    formData.append("nameNepali", members[index].nameNepali);
    formData.append("image", members[index].photo);
    formData.append("position", members[index].position);
    formData.append("positionNepali", members[index].positionNepali);
    formData.append("description", members[index].description);
    formData.append("descriptionNepali", members[index].descriptionNepali);
    formData.append("role", members[index].role);
    formData.append("_id", members[index]._id);
    formData.append("indexNo", members[index].indexNo);
    try {
      await updateBoardCommitteeMember(memberToUpdate._id, formData);
      toast.success("Member has been edited successfully", {
        position: "top-center",
        autoClose: 700,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
        theme: "colored",
      });
      fetchData();
    } catch (error) {
      toast.error(`${JSON.stringify(error)} `, {
        position: "top-center",
        autoClose: 700,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
        theme: "colored",
      });
    }
  };

  const handleDelete = async (id) => {
    try {
      await deleteBoardCommitteeMember(id);
      toast.success("Deleted successfully", {
        position: "top-center",
        autoClose: 700,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
        theme: "colored",
      });
      fetchData();
    } catch (error) {
      toast.error(error.response.data.errormessage);
    }
  };

  return (
    <>
      <h5>All Members List</h5>
      <Table className="table table-striped table-dark">
        <thead>
          <tr>
            <th>S.N</th>
            <th>Index</th>
            <th>Image</th>
            <th>Name</th>
            <th>Name Nepali</th>
            <th>Gender</th>
            <th>Position</th>
            <th>Position Nepali</th>
            <th>Description</th>
            <th>Description Nepali</th>
            <th>Role</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {members.length > 0 ? (
            members.map((element, index) => (
              <tr key={element._id}>
                <td>{index + 1}</td>
                <td>
                  <input
                    type="number"
                    style={{ width: "50px" }}
                    value={element.indexNo}
                    onChange={(e) => handleChange(index, "indexNo", e)}
                  />
                </td>
                <td>
                  <label htmlFor={`image-${index}`}>
                    <img
                      style={{
                        borderRadius: "50%",
                        width: "50px",
                        height: "50px",
                        cursor: "pointer",
                        overflow: "hidden",
                      }}
                      src={element.photo}
                      alt=""
                    />
                  </label>
                  <input
                    id={`image-${index}`}
                    type="file"
                    name="photo"
                    onChange={(e) => {
                      handleImageChange(index, "photo", e);
                    }}
                    style={{ display: "none", borderRadius: "50px" }}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={element.name}
                    onChange={(e) => handleChange(index, "name", e)}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={element.nameNepali}
                    onChange={(e) => handleChange(index, "nameNepali", e)}
                  />
                </td>

                <td>
                  <select
                    value={element.gender}
                    onChange={(e) => handleChange(index, "gender", e)}
                  >
                    <option>Male</option>
                    <option>Female</option>
                    <option>Others</option>
                  </select>
                </td>
                <td>
                  <input
                    type="text"
                    value={element.position}
                    onChange={(e) => handleChange(index, "position", e)}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={element.positionNepali}
                    onChange={(e) => handleChange(index, "positionNepali", e)}
                  />
                </td>
                <td>
                  <textarea
                    name="description"
                    minRows="5"
                    value={element.description}
                    onChange={(e) => handleChange(index, "description", e)}
                  ></textarea>
                </td>
                <td>
                  <textarea
                    name="descriptionNepali"
                    minRows="5"
                    value={element.descriptionNepali}
                    onChange={(e) =>
                      handleChange(index, "descriptionNepali", e)
                    }
                  ></textarea>
                </td>
                <td>
                  <select
                    value={element.role}
                    onChange={(e) => handleChange(index, "role", e)}
                  >
                    <option value="Board Member">Board Member</option>
                    <option value="Core Team">Core Team</option>
                    <option value="Project Staff and Consultants">
                      Project Staff and Consultants
                    </option>
                  </select>
                </td>
                <td>
                  <Button onClick={handleSubmit(index)}>Update</Button>
                </td>
                <td>
                  <Button
                    style={{
                      color: "red",
                    }}
                    onClick={() => handleDelete(element._id)}
                  >
                    Delete
                  </Button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="8">No members found</td>
            </tr>
          )}
        </tbody>
      </Table>

      <ToastContainer />
    </>
  );
};

export default BoardCommitteeMembers;
