import React, { useEffect, useState } from "react";
import {
  getAboutUsImages,
  getBannerImages,
  getOurPartner,
} from "../../services/api";
import coverImage from "../../assets/img/cover.jpg";
import { getOurPartnerHeading } from "../../services/ourPartnerService";
import Accessibility from "./Accessibility";
import { NavLink } from "react-router-dom";

const AboutUsOurPartners = () => {
  const [aboutUsImages, setAboutUsImages] = useState();
  const [currentLocale, setcurrentLocale] = useState("EN");
  const [ourPartners, setourPartners] = useState({});

  const fetchData = async () => {
    try {
      const res = await getBannerImages();
      const resData = res.data;
      if (resData) {
        setAboutUsImages(resData);
      }

      const response = await getOurPartner();
      setourPartners(response.data);
    } catch (error) {}
  };

  useEffect(() => {
    fetchData();
    let currentLocale = localStorage.getItem("locale")
      ? localStorage.getItem("locale") === "eng"
        ? "EN"
        : "NP"
      : "EN";
    setcurrentLocale(currentLocale);
  }, []);

  return (
    <>
      <section className="about-us-section ">
        <Accessibility />
        <div
          className="banner"
          style={{
            backgroundImage: `url(${
              aboutUsImages && aboutUsImages.aboutUsOurPartners
                ? aboutUsImages.aboutUsOurPartners
                : coverImage
            })`,
          }}
        >
          <div className="banner-content">
            <h1 className="text-white text-center">
              {currentLocale === "EN" ? "Our Partners" : "हाम्रा साझेदारहरू"}
            </h1>
          </div>
        </div>

        <div className="container">
          <div>
            <div className="card-body">
              <div className="row mt-4">
                {ourPartners && ourPartners.partner
                  ? ourPartners.partner.map((item, index) => (
                      // <div
                      //   key={index}
                      //   className="col-12 col-lg-3 d-flex flex-column align-items-center mt-4"
                      // >
                      //   <img
                      //     src={item.image ? item.image : ""}
                      //     alt=""
                      //     className="testimonials-img"
                      //   />
                      // </div>

                      <div key={index} class="col-12 col-md-4 col-xl-2 mb-3">
                        <div class="team-member d-flex flex-column align-items-center">
                          <img
                            src={item.image}
                            alt="team member"
                            class="img-fluid our-team-image"
                          />
                          <NavLink
                            to={`/ourPartner/readMore/${item._id}`}
                            className="text-blue mb-2"
                          >
                            {currentLocale
                              ? currentLocale === "EN"
                                ? "Read More"
                                : "थप पढ्नुहोस्"
                              : "Read More"}{" "}
                            <i className="fas fa-circle-arrow-right ml-2"></i>
                          </NavLink>
                        </div>
                      </div>
                    ))
                  : null}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutUsOurPartners;
